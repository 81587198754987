import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  Typography
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AxiosProgressEvent } from 'axios';
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import myapi from "../../../API/MyAPI";
import { EnumServiceType } from '../../../Types/Enums/EnumServiceType';
import { RFQItemsModelDTO, RFQModelDTO } from '../../../Types/RFQModelDTO';
import { getOptionLabel } from "../../../_Helper/AutoCompleteGetLabel";
import { countries } from "../../../_Helper/CountryStateCity";
import { EnglishRegex } from '../../../_Helper/RegexHelper';
import { EnumUnit } from "../../../components/Enums/EnumUnit";
import FieldCitySelector from '../../../components/Fields/FieldCitySelector';
import FieldPriceCurrency from "../../../components/Fields/FieldPriceCurrency";
import FieldStateSelector from '../../../components/Fields/FieldStateSelector';
import BTextField from '../../../components/atomic/BTextField';
import LoadingButton from "../../../components/atomic/LoadingButton";
import useFormValidation, { FormValidation } from '../../../hooks/useFormValidation';
import I18n from "../../../i18n/i18n";
import { ShowError, ShowSucced } from "../../../redux/Actions/AlertAction";

const MAX_LENGTH = 200;

function UploadInput(props: any) {
  const { onChange } = props

  const [file, setFile] = useState<any>(null);
  const [imageSrc, setImageSrc] = useState<string>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleUpload = (event: any) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    onChange(event.target.files[0]);
  };

  return (
    <>
      <div>
        <input
          accept="image/*"
          hidden
          id="upload-file"
          type="file"
          onChange={handleUpload}
        />
        <label htmlFor="upload-file">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudDownloadIcon />}
          >
            <I18n id="Upload" />
          </Button>
        </label>
        {file && (
          <Typography
            variant="body1"
            mt={1}
            sx={{
              backgroundColor: "#d1d1d14f",
              borderRadius: "0.5rem",
              padding: "0.5rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {file.name}
            <Button
              size="small"
              onClick={() => {
                setImageSrc(URL.createObjectURL(file));
                setIsDialogOpen(true);
              }}
            >
              {" "}
              <VisibilityIcon />{" "}
            </Button>
          </Typography>
        )}
      </div>

      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          URL.revokeObjectURL(imageSrc ?? '');
        }}
      >
        <DialogContent>
          <Box className="img-fluid" component="img" src={imageSrc} />
        </DialogContent>
      </Dialog>
    </>
  );
}

interface ItemDetailDataProps extends RFQItemsModelDTO {
  productCategory?: number;
  productNameEn: string;
}

function RFQuotation(props: any) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);


  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [price, setPrice] = useState(1);
  const [attachment, setAttachment] = useState<any>();

  const [formData, setFormData] = useState<RFQModelDTO>({
    deadlineDate: new Date(),
    itemModels: [],
    statusHistories: [],
    sendDate: new Date(),
    requestDate: new Date(),
    serviceType: EnumServiceType.ItemRFQ,
  });
  const [itemDetail, setItemDetail] = useState<ItemDetailDataProps>({
    productName: queryParams.get("productName") ?? "",
    amount: parseFloat(queryParams.get("amount") ?? '1'),
    productNameEn: '',
    translates: []
  });

  const [customFieldDecription, setCustomFieldDecription] = useState<any>({});

  const resizeRef = useRef<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    RFQFormValidation.validate(false);
  }, [formData]);

  useEffect(() => {
    ItemRFQFormValidation.validate(false);
  }, [itemDetail]);

  const formValidation: FormValidation<RFQModelDTO>[] = [
    { field: "destinationCountryCode", type: "autocomplete" },
    { field: "destinationStateCode", type: "autocomplete" },
    { field: "destinationCityCode", type: "autocomplete" },
  ]

  const formRFQItemValidation: FormValidation<ItemDetailDataProps>[] = [
    { field: "amount", type: "number" },
    { field: "productCategory", type: "autocomplete" },
    {
      field: "currency", type: "custom", customfield:true, forceUseCustom:true, customValidation: _val => {
        const price = itemDetail.price
        console.log(price)
        if (price) {
        console.log(itemDetail.currency)
        return itemDetail.currency == undefined
        }

        return false;
      }
    },
    { field: "unitType", type: "autocomplete" },
    {
      field: "productNameEn", type: "custom", valueGetter: () => {
        console.log(itemDetail?.translates)
        return itemDetail?.translates.find(x => x.property == 'productName' && x.Locale == 'en')?.translated_value
      },
      customValidation: _val => {
        const val = itemDetail?.translates.find(x => x.property == 'productName')?.translated_value
        if (val)
          return (val.length == 0 || !EnglishRegex.test(val))

        return false;
      }
    },
  ]

  const successfulLogin = useSelector((state: any) => state.auth.loggedIn)

  const RFQFormValidation = useFormValidation(formValidation, formData);
  const ItemRFQFormValidation = useFormValidation(formRFQItemValidation, itemDetail);

  const [categories, setCateogories] = useState<any>([])

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.id]: e.target.value })
  }

  const handleChangeItemDetail = (e: any) => {
    setItemDetail({ ...itemDetail, [e.target.id]: e.target.value })
  }

  const handleSubmit = () => {
    const rfqValid = RFQFormValidation.validate(true)
    const itemValid = ItemRFQFormValidation.validate(true)

    if (rfqValid && itemValid) {
      let data = { ...formData, sendDate: dayjs(), fieldsDescriptions: JSON.stringify(customFieldDecription), itemModels: [{ ...itemDetail }] };

      setLoading(true)

      var postFormdata = new FormData()
      postFormdata.append('formjson', JSON.stringify(data));
      postFormdata.append("formFiles", attachment);

      // myapi.post(`/RFQ/AddPrductRequest`, postFormdata, {
      //   headers: { "Content-Type": "multipart/form-data" },
      //   onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      //     const progress = 50 + (progressEvent.loaded / (progressEvent.total ?? 1)) * 50;
      //     setUploadProgress(progress)
      //   },
      // })
      //   .then((result) => {
      //     let res = result.data;

      //     if (res.statusCode == 200) {
      //       dispatch(ShowSucced("Your Request Submitted !", "RFQ"));
      //       setTimeout(
      //         function () {
      //           navigate("../dashboard/rfqs");
      //         }.bind(resizeRef),
      //         1000
      //       );
      //     } else {
      //       dispatch(ShowError(res.data, "RFQ"));
      //     }
      //   }).finally(() => setLoading(false));
    }
  }

  useEffect(() => {

    if (!successfulLogin) {
      navigate(`/login?redirect=/rfq-questions`)
    }

    myapi.get(`/Category/GetAllCategories`).then(data => {
      if (data.data.statusCode == 200) {
        setCateogories(data.data.data.sort(function (a: any, b: any) {
          return a.categoryName.localeCompare(b.categoryName);
        }))
      }
    })
  }, [])

  return (
    <Container
      sx={{ zIndex: 2, height: "100%", background: "white", borderRadius: "1rem" }}
      maxWidth={"md"}
    >
      <Grid container py={3} spacing={1}>
        <Grid item xs={12}>
          <FormLabel required><I18n id="productNameEnglish" /></FormLabel>
          <BTextField
            required
            error={ItemRFQFormValidation.errors['productNameEn']}
            value={itemDetail?.translates.find(x => x.property == 'productName')?.translated_value}
            id="productNameEn"
            onChange={(e: any) => setItemDetail({ ...itemDetail, translates: [{ Locale: 'en', property: 'productName', translated_value: e.target.value }] })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel><I18n id="rfquotationform.localProductName" /></FormLabel>
          <BTextField
            value={itemDetail.productName}
            id="productName"
            onChange={(e: any) => handleChangeItemDetail(e)}
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel required><I18n id='productCategory' /></FormLabel>
          <Autocomplete
            value={itemDetail?.productCategory}
            getOptionLabel={(x: any) =>
              x &&
              intl.formatMessage({
                id: x.categoryName
                  .replace("&", "")
                  .replace(/\s/g, "")
                  .toLowerCase(),
              })
            }
            id="productCategory"
            onChange={(e, selected: any) =>
              setItemDetail({ ...itemDetail, "productCategory": selected ?? undefined })
            }
            options={categories}
            renderInput={(params) =>
              <BTextField
                errorPostfix="rfq.error"
                error={ItemRFQFormValidation.errors['productCategory']}
                {...params} />}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography>
            <I18n id="description" />{" "}
            {`(${MAX_LENGTH - (formData.description?.length ?? 0)}`} <I18n id="charRemain" /> {")"}
          </Typography>
          <BTextField
            value={formData.description}
            id="description"
            error={RFQFormValidation.errors['description']}
            onChange={(e: any) =>
              setFormData({ ...formData, description: e.target.value.slice(0, MAX_LENGTH) })
            }
            rows={5}
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <UploadInput
            onChange={(file: any) =>
              setAttachment(file)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <BTextField
            value={(Number.isNaN(itemDetail.price) ? "" : itemDetail.price)}
            id="price"
            labelid="price"
            error={ItemRFQFormValidation.errors['price']}
            onChange={(e: any) => setItemDetail({ ...itemDetail, price: parseFloat(e.target.value) })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormLabel><I18n id='currency' /></FormLabel>
          <FieldPriceCurrency
            required
            renderInputProps={{ error: (id: any, value: any) => ItemRFQFormValidation.errors['currency'] }}
            value={itemDetail.currency}
            onChange={(selected: any) => {
              setItemDetail({ ...itemDetail, currency: selected?.id })
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <BTextField
            required
            error={ItemRFQFormValidation.errors['amount']}
            value={itemDetail.amount}
            type='number'
            id="amount"
            labelid="amount"
            onChange={(e: any) => setItemDetail({ ...itemDetail, "amount": parseFloat(e.target.value) })}
            placeholder="100"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormLabel required><I18n id='unitTitle' /></FormLabel>
          <Autocomplete
            value={EnumUnit.find((x) => x.id == itemDetail.unitType)}
            getOptionLabel={(x) =>
              intl.formatMessage({ id: x.label.toLowerCase() })
            }
            onChange={(e, selected) =>
              setItemDetail({ ...itemDetail, "unitType": selected?.id })
            }
            options={EnumUnit}
            renderInput={
              (params) => <BTextField
                {...params}
                id={'unitType'}
                error={ItemRFQFormValidation.errors['unitType']}
                placeholder={intl.formatMessage({ id: "choose" })} />
            }
          />
        </Grid>
        {itemDetail.unitType == 13 &&
          <Grid item xs={12} sm={6} md={4}>
            <FormLabel required className="typo" >
              <I18n id="addproduct.unitType.description" />
            </FormLabel>
            <BTextField
              required
              error={ItemRFQFormValidation.errors['unitType']}
              id="payments.other.title"
              placeholder={intl.formatMessage({ id: 'addproduct.unitType.placeholder' })}
              value={customFieldDecription.unitType}
              onChange={(e: any) => {
                setCustomFieldDecription({
                  ...customFieldDecription,
                  unitType: e.target.value
                })
              }} />
          </Grid>}
        <Grid item xs={12} sm={4} md={4}>
          <FormLabel required><I18n id='destinationCountry' /></FormLabel>
          <Autocomplete
            value={formData.destinationCountryCode}
            getOptionLabel={(option) => getOptionLabel(countries, option)}
            onChange={(e, selected: any) =>
              setFormData({ ...formData, destinationCountryCode: selected ? selected.id : undefined })
            }
            options={countries}
            renderInput={(params) => (
              <BTextField
                {...params}
                id="destinationCountryCode"
                error={RFQFormValidation.errors['destinationCountryCode']}
                placeholder={intl.formatMessage({ id: "choose" })} />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FieldStateSelector
            required={true}
            label='destinationState'
            placeholder="Choose"
            value={formData.destinationStateCode}
            countryCode={formData.destinationCountryCode ?? -1}
            onChange={(selected: any) => {
              setFormData({ ...formData, destinationCityCode: undefined, destinationStateCode: selected ? selected.id : undefined });
            }}
            renderInputProps={{
              errorPostfix: "rfq.error",
              error: RFQFormValidation.errors['destinationStateCode']
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FieldCitySelector
            required={true}
            label='destinationCity'
            value={formData.destinationCityCode}
            countryCode={formData.destinationCountryCode}
            stateCode={formData.destinationStateCode}
            onChange={(selected: any) => {
              setFormData({ ...formData, destinationCityCode: selected ? selected.id : undefined })
            }}
            placeholder="Choose"
            renderInputProps={{
              errorPostfix: "rfq.error",
              error: RFQFormValidation.errors['destinationCityCode']
            }}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4}>
          <FormLabel required><I18n id='receiveDate' /></FormLabel>
          <MobileDatePicker
            required
            disablePast
            sx={{ width: "100%" }}
            id="receiveDate"
            value={formData.receiveDate}
            onChange={(date) => formik_rfq.setFieldValue("receiveDate", date)}
            slots={{
              ActionBar: DatePickerActions,
            }}
            slotProps={{
              textField: { size: "small" },
              actionBar: {
                actions: ["accept", "cancel"],
              },
            }}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={4}>
          <FormLabel required><I18n id='deadlineDate' /></FormLabel>
          <MobileDatePicker
            disablePast
            sx={{ width: "100%" }}
            value={formData.deadlineDate ? dayjs(formData.deadlineDate) : ''}
            onChange={(date: any) => setFormData({ ...formData, deadlineDate: date })}
            slotProps={{
              textField: { size: 'small' },
              actionBar: {
                actions: ["accept", "cancel"],
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={loading}
            onClick={() => handleSubmit()}
            variant="contained"
            color="primary"
            sx={{ width: "100%" }}
          >
            {" "}
            <I18n id="submit" />
          </LoadingButton>
        </Grid>
      </Grid>
    </Container>
  );
}

export default RFQuotation;
