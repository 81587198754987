import { useState } from 'react';

export interface FormValidation<T> {
    field: keyof T;
    section?: string;
    errorText?: string;
    required?: boolean;
    customfield?: boolean;
    forceUseCustom?: boolean;
    type: "text" | "autocomplete" | "number" | "custom";
    customValidation?: (value: any) => boolean | undefined;
    valueGetter?: () => string | undefined | any;
}

export type FormErrors<T> = {
    [K in keyof T]?: boolean;
};

export type SectionErrors = {
    [K: string]: boolean;
};

export interface FormValidationResult<T> {
    isValid: boolean;
    errors: FormErrors<T>;
    sectionErrors: SectionErrors;
    validate: (submit: boolean, section?: string) => boolean;
    FieldHelper: (id: keyof T) => any
}

const useFormValidation = <T>(formValidation: FormValidation<T>[], formData: T): FormValidationResult<T> => {
    const [sectionErrors, setSectionError] = useState<SectionErrors>({});
    const [errors, setErrors] = useState<FormErrors<T>>({});
    const [touched, setTouched] = useState<FormErrors<T>>({});

    const FieldHelper = (id: keyof T) => {
        return {
            error: errors[id],
            helperText: errors[id] && formValidation.find(x => x.field == id)?.errorText,
            id: id,
            value: formData[id],
            onFocus: () => setTouched((prevErrors) => ({ ...prevErrors, [id]: true }))
        }
    }

    const validate = (submit: boolean, section?: string) => {


        let _errors: FormErrors<T> = errors;
        let _sectionErrors: SectionErrors = {};

        formValidation.forEach((element) => {
            let customValue = undefined;
            if (element.valueGetter) {
                customValue = element.valueGetter()
            }

            let fieldValue = formData[element.field];

            if (customValue)
                fieldValue = customValue

            let forceCheck = submit
            if (section && element.section)
                forceCheck = forceCheck || element.section == section

            if (((fieldValue || (element.forceUseCustom && touched[element.field])) && element.customfield) || (element.customfield && forceCheck)) {
                _errors[element.field] = element.customValidation && element.customValidation(fieldValue);
            }
            else if (fieldValue || touched[element.field]) {
                setTouched((prevErrors) => ({ ...prevErrors, [element.field]: true }))
                switch (element.type) {
                    case "custom":
                        _errors[element.field] = element.customValidation && element.customValidation(fieldValue);
                        break;
                    case "autocomplete":
                        _errors[element.field] = fieldValue === undefined;
                        break;
                    case "text":
                        _errors[element.field] = (fieldValue as string).trim() === "";
                        break;
                    case "number":
                        _errors[element.field] = isNaN((fieldValue as number));
                        break;
                    default:
                        break;
                }
            } else if (forceCheck) {
                if (element.required == undefined)
                    _errors[element.field] = true;

                if (element.required && element.required == true)
                    _errors[element.field] = true;
                // else
                //     _errors[element.field] = false;
            }

            if (element.section) {
                _sectionErrors[element.section] = _sectionErrors[element.section] || _errors[element.field] as boolean
            }
        });

        Object.keys(_sectionErrors).forEach(x => {
            if (sectionErrors[x] == undefined)
                sectionErrors[x] = _sectionErrors[x]
            else
                sectionErrors[x] = _sectionErrors[x]
        })

        setSectionError(_sectionErrors)
        setErrors(_errors);

        return !Object.values(_errors).some((x) => x === true);
    };

    return {
        FieldHelper,
        isValid: !Object.values(errors).some((x) => x === true),
        errors,
        sectionErrors,
        validate,
    };
};

export default useFormValidation;
