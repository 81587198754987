import React, { useState, useEffect } from "react";
import Cookies from "js-cookie"; // For managing cookies
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Link,
} from "@mui/material";

const CookieConsent = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Check if the user has already consented
    const hasConsented = Cookies.get("cookie_consent") === "true";
    if (!hasConsented) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    // Set a cookie to remember the user's consent
    Cookies.set("cookie_consent", "true", { expires: 365 }); // Expires in 1 year
    setOpen(false);
  };

  const handleDecline = () => {
    // Optionally handle decline (e.g., set a cookie to remember the choice)
    Cookies.set("cookie_consent", "false", { expires: 365 });
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleDecline}>
      <DialogTitle>We Use Cookies</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          We use cookies to enhance your experience. By continuing to visit this
          site, you agree to our use of cookies.{" "}
          <Link href="/privacy-policy" color="primary">
            Learn more
          </Link>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDecline} color="info">
          Decline
        </Button>
        <Button onClick={handleAccept} color="primary" variant="contained">
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookieConsent;